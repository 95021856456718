.navbar {
  padding: 0 8px;
  border-bottom: none;
  overflow: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: fixed;
  z-index: 50;
  width: 100%;
  height: 56px;
  background-color: var(--tertiary-color);
}

@media (min-width: 768px) {
  .mobileRightMenu {
    display: none;
  }

  .navbar {
    padding: 0 8px;
    border-bottom: none;
    overflow: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    flex-shrink: 0;
    position: fixed;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    background-color: var(--tertiary-color);
  }
}


@media (max-width: 768px) {
  .navbar {
    width: 100%;
    background-color: var(--tertiary-color);
  }
}
