.title {
  margin: 0;
  float: left;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  color: black;
}
.mb-px {
  margin-bottom: 1rem;
}