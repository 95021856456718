.transferWrapper {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transferWrapper > .ant-transfer {
  display: flex;
  width: 100%;
  align-items: center;
}

.transferWrapper > .ant-transfer > .ant-transfer-operation {
  width: 50px;
}

@media (max-width: 576px) {
  .transferWrapper > .ant-transfer {
    flex-direction: column;
  }

  .transferWrapper > .ant-transfer > .ant-transfer-operation {
    transform: rotate(90deg);
    width: unset;
  }
}
