@import '~antd/dist/antd.css';

:root {
  --primary-color: #0000FF;
  --secondary-color: #001d8a;
  --tertiary-color: #FFFFFF;
  --main-blue: #001d8a;
  --white: #fff;
  --light-grey: #f3f1f1;
  --light-dark-grey: #e8e8e8;
  --light-blue: #cfdae6;
  --dark-blue: #5b7186;
  --light-dark-grey-two: #f5f5f5;
  --light-dark-grey-three: #d0d0d0;
  --light-green: #50ae55;
  --light-green-2: #4fbd3b;
  --light-orange: #efaf41;
  --dark-green: #198e20;
  --facebook: #3b5998;
  --twitter: #00acee;
  --whatsapp: #25d366;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  overflow-x: auto !important;
}

.ant-table-header-column {
  width: 100%;
}

.view-only-input-with-addon-after > span > input {
  background-color: #f9f9f9 !important;
  color: #000000a6 !important
}

.mb-spin > span > i {
  background-color: #FFC83D !important;
}

.topup-popular-column {
  background-color: #F3F8FB;
}

.my-topup-table-header {
  background-color: white;
}

.my-topup-table-row {
  background-color: white;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: none;
}

.ant-btn-primary {
  background-color: var(--primary-color);
  outline-color: var(--primary-color);
  border-color: var(--primary-color);;
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
  background-color: var(--secondary-color);
  outline-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.button__transparent.ant-btn:focus,  .button__transparent.ant-btn:hover,
.ant-menu-item>a:hover {
  color: var(--secondary-color);
}

.ui-sidebar .ant-menu-item-selected > a,
.ant-drawer-body .ant-menu-item-selected > a {
  color: var(--secondary-color);
}

.ui-page-tabs .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:active,
.ant-tabs-nav .ant-tabs-tab:hover,
.ant-menu-item-active, .ant-menu-item:hover, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ui-sidebar .home-menu .home-submenu-item.ant-menu-item > a:hover, .ui-sidebar .home-menu .home-submenu-item.ant-menu-item.ant-menu-item-selected > a,
.ant-menu-item-selected, .ant-menu-item-selected>a, .ant-menu-item-selected>a:hover,
.checklist-container .title span,
a, a:hover {
  color: var(--secondary-color);
}

.checklist-container .get-start-container .ant-menu-inline .ant-menu-item:after,
.ant-input:focus, .ant-input:hover {
  border-color: var(--secondary-color);
}

.ant-tabs-ink-bar,
.ant-switch-checked {
  background-color: var(--secondary-color);
}

.ant-steps-item-title {
  width: 100%;
  padding: 0;
}

.ant-input-search-button {
  background-color: transparent;
  box-shadow: none;
  outline: var(--dark-blue);
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}

.ant-input-search-button:hover {
  background-color: transparent;
  box-shadow: none;
  outline: var(--dark-blue);
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}

.ant-input-search-button:focus {
  background-color: transparent;
  box-shadow: none;
  outline: var(--dark-blue);
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}

.color__blue {
  color: var(--main-blue);
}

.color_facebook {
  color: var(--facebook);
}

.color_twitter {
  color: var(--twitter);
}

.color_whatsapp {
  color: var(--whatsapp);
}

.heading1 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  color: black;
}

.heading2 {
  display: block;
  font-size: 1.5em;
  /* margin-top: 0.83em; */
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  color: black;
}

.heading3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  color: #454545;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.heading4 {
  white-space: normal;
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 50px;
  font-weight: bold;
  color: black;
  overflow: hidden;
}

.ant-upload-text {
  font-size: 10px;
  text-overflow: ellipsis;
  text-align: center;
}

.modal-img-view .ant-modal-body {
  padding: 0 !important;
  min-width: 600px;
  min-height: 600px;
}

.modal-img-view img {
  height: 100%;
  width: 100%;
}

.authentication-alert {
  margin: 20px 0;
}

.forgot-password-button {
  float: right;
}

.forgot-password-button > button {
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
}

.spacing-form-right {
  padding-right: 10px;
}

.spacing-form-left {
  padding-right: 10px;
}

.footer-button {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  height: 100%;
}

.icon {
  font-size: 1em;
}

.align-center {
  margin: 40px auto;
}

.button-success {
  background-color: var(--light-green);
  color: var(--white);
  border: 1px solid var(--dark-green);
}

.button-success:hover {
  background-color: var(--light-green-2);
  color: var(--white);
  border: 1px solid var(--dark-green);
}

.button-success:focus {
  background-color: var(--light-green);
  color: var(--white);
  border: 1px solid var(--dark-green);
}

.bg-warning {
  background-color: var(--light-orange);
}

.button-bg-orange {
  color: var(--light-orange);
}

.button-bg-transparent {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

.button-bg-transparent:hover {
  background-color: transparent;
}

.button-bg-transparent:focus {
  background-color: transparent;
}

.margin-side-10px {
  margin: 0 10px;
}

.mobile-search-toggle.right-menu-button-container {
  display: none;
}

.mobile-search-container {
  position: absolute;
  padding: 11px;
  top: 0;
  height: 56px;
  background: #001d8a;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 55px);
  z-index: 4;
}

.mobile-search-container > ul {
  border: none;
  background: #001d8a;
  width: 100%;
}

.mobile-search-container > ul > li {
  padding: 0;
  width: 100%;
}

.ui-container {
  display: flex;
  flex-direction: column;
}

.ui-sidebar {
  padding-top: 68px;
  min-height: 100vh;
  background: #f0f2f5 !important;
  border-right: 1px solid #d9d9d9;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
}

.ui-sidebar .home-menu {
  background-color: #f0f2f5;
  min-height: auto;
  border: none;
  width: 100%;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-btn,
.ant-menu-inline-collapsed > .ant-menu-item .ant-dropdown-trigger {
  display: none;
}

.ant-menu-inline-collapsed > .ant-menu-item .icon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}

.ant-menu-inline-collapsed > .ant-menu-item .button-new-order.small {
  display: inline-block;
}

.button-new-order.small {
  display: none;
}

.ant-layout-sider {
  background-color: transparent;
  background: transparent;
}

.button-full-width {
  width: 100%;
}

.button-full-width .ant-btn {
  width: 80%;
}

.button-full-width .ant-dropdown-trigger {
  width: 20%;
}

.paddingMenuItem {
  padding-bottom: 10px;
}

.blueColor {
  background-color: var(--main-blue);
}

.whiteColor {
  color: var(--white);
}

.whiteBColor {
  background-color: var(--white);
}

.borderLightDarkGrey {
  color: 1px solid var(--light-dark-grey);
}

.lightBlueBackgroundColor {
  background-color: var(--light-blue);
}

.lightDarkGreyTwoBColor {
  background-color: var(--light-dark-grey-two);
}

.lightDarkGreyThreeBColor {
  background-color: var(--light-dark-grey-three);
}

.darkBlueColor {
  color: var(--dark-blue);
}

.borderNone {
  border: none;
}

.button-sidebar {
  position: fixed;
  top: 12px;
  left: 0;
  z-index: 4;
}

.ant-input-group .ant-input-affix-wrapper {
  height: 32px;
}

.footer-link {
  text-align: center;
  margin-top: 16px;
}

.footer-link button {
  padding: 0 8px;
  font-weight: 500;
}

.bg-blue {
  background-color: #294ea7;
  border-color: #294ea7;
}

.ui-main-content {
  display: flex;
  flex-direction: column;
  margin: 56px 0 0;
  width: 100%;
  min-height: calc(100vh - 56px);
}

.ui-right-content {
  padding: 30px 20%;
  overflow: auto;
  flex-grow: 1;
  min-height: calc(100vh - 56px);
}

.ui-right-content.form {
  overflow: auto;
  flex-grow: 1;
  min-height: calc(100vh - 56px);
}

.ant-menu-inline-collapsed > .ant-menu-item {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.ui-right-content.ui-footer {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 230px);
  min-width: calc(100% - 230px);
  max-width: 100%;
  min-height: unset;
}

.checkout-button {
  display: none;
}

.ui-form-iq {
  padding: 50px;
  border: 1px solid #cfdae6;
  position: relative;
  display: flex;
  width: 100%;
}

.desc {
  font-size: 14px;
  color: #454545;
}

/**
 * Home
 **/

.announcement-card {
  padding: 24px;
  margin: 0;
  height: 92px;
  vertical-align: middle;
  line-height: 50px;
  text-align: center;
}

.first-row-statistic-card {
  padding-bottom: 30px;
}

.second-row-left-statistic-card-title {
  float: left;
  width: 58%;
}

.second-row-right-statistic-card-title {
  float: left;
  width: 35%;
}

.initial-statistic-card {
  cursor: pointer;
  text-align: center;
  border: 2px solid #cfdae6;
  /* border-right: none; */
  margin-right: -2px;
  margin-bottom: -2px;
  overflow: hidden;
  position: relative;
}

.final-statistic-card {
  text-align: center;
  border: 2px solid #cfdae6;
  min-height: 152px;
  overflow: hidden;
  position: relative;
}

.statistic-title .ant-statistic-title {
  margin-top: 10px;
}

.second-row-statistic-card {
  padding: 30px 0;
}

.ant-list-footer {
  padding: 0;
}

.ant-list-bordered .ant-list-footer {
  padding: 0;
}

.center {
  margin: auto;
  width: 30%;
  padding: 30px;
  max-height: fit-content;
}

.OrLine {
  float: left;
  width: 33%;
}

.button-sidebar {
  position: fixed;
  top: 12px;
  left: 0;
  z-index: 4;
}

.color__white {
  color: var(--white);
}

.button-sidebar .sidebar-btn-icon {
  font-size: 20px;
}

.button__transparent {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

.button__transparent:hover {
  background-color: transparent;
}

.button__transparent:focus {
  background-color: transparent;
}

.logo {
  width: 199px;
  position: relative;
  margin: auto;
}

.logo a {
  display: inline-block;
  font-size: 16px;
  padding: 0 10px;
  text-transform: capitalize;
  position: relative;
  left: 55px;
}

.logo .img-logo {
  max-width: 156px;
  max-height: 56px;
  position: relative;
  width: 136px;
  height: auto;
}

.menuVer {
  border-right: 1px solid var(--light-dark-grey);
  width: 230px;
}

.navbar-container {
  width: calc(100% - 199px);
  display: flex;
  justify-content: space-between;
}

.navbar-container .left-menu-item {
  /* padding-bottom: 10px; */
  flex-shrink: inherit;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.navbar-container .left-menu-item:hover,
.navbar-container .left-menu-item:active,
.navbar-container .left-menu-item:focus,
.left-menu-item.search-bar {
  border-bottom: 0px !important;
}

.navbar-container .side-padding {
  padding: 0 150px;
}

.navbar-container .left-menu-item .left-menu-input {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  /* max-width: 694px; */
}

.navbar-container .left-menu-item .left-menu-input input.ant-input {
  background-color: #294ea7;
  border: 0;
  color: #fff;
}

.navbar-container .left-menu-item .left-menu-input input.ant-input:focus {
  background-color: #fff;
  color: #454545;
}

.navbar-container .left-menu-item .ui-icon-navbar {
  padding: 0;
  position: relative;
  display: inline-block;
}

.navbar-container .left-menu-item .ui-icon-navbar .button-plus {
  padding: 10px;
}

.navbar-container .ant-menu-submenu-title {
  padding: 10px 20px;
}

.navbar-container .ant-menu-item a,
.navbar-container .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.navbar-container .ant-menu-horizontal {
  border-bottom: none;
  line-height: 35px;
  display: flex;
  width: 100%;
}

.navbar-container .leftMenu {
  float: left;
  width: calc(100% - 230px);
}

.navbar-container .rightMenu {
  width: 180px;
  position: relative;
  right: 16px;
}

.ant-avatar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.avatar-name {
  padding: 0 8px 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
}

.avatar-desc {
  padding: 0 8px 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
  font-size: 0.8em;
}

.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 10px;
  display: none;
  background: none;
  z-index: 3;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: var(--white);
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: var(--white);
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

.button-fullWidth {
  width: 100%;
}

.button-upload {
  position: relative;
  width: 500px;
  display: inline-block;
}

.button-upload .ant-upload.ant-upload-select {
  float: right;
  width: 30%;
  display: flex;
  justify-content: center;
}

.button-upload .ant-upload-list {
  display: block;
  min-height: 32px;
  float: left;
  width: 70%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
}

.button-upload .ant-upload-list-item {
  margin: 4px 0;
}

.button-upload .ant-upload-list-item-info {
  padding: 0 12px;
}

.no-margin-top {
  margin: 0;
}

.button-google {
  background: #db3236;
  color: #fff;
  height: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}

.button-google:hover {
  background: #e94749;
  color: #fff;
  height: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}

.button-facebook {
  background: #3b5998;
  color: #fff;
  height: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}

.button-facebook:hover {
  background: #4b68a8;
  color: #fff;
  height: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}

.button-nationalID {
  background: #010066;
  color: #fff;
  height: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}

.payment-dropdown .ant-select {
  width: 100%;
}

.button-nationalID:hover {
  background: #05037c;
  color: #fff;
  height: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}

.login-form-button {
  color: #fff;
  height: 32px;
  font-weight: 600;
}

.button-forget {
  color: rgba(0, 0, 0, 0.65);
  float: right;
}

.button-template {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: underline;
}

.button-template:hover {
  color: rgb(0, 0, 0);
}

.marginBottom-10 {
  margin-bottom: 10px;
}

.marginBottom-40 {
  margin-bottom: 40px;
}

/**
 * Settings
 **/

.menu-icon {
  width: 15%;
}

.color-logout {
  color: red;
}

/**
 * Sidebar
 **/

.submenu-icon {
  width: 20px;
}

.sidebar-menu-icon {
  width: 40px;
}

.bordered-sidebar-menu {
  border-bottom: 1px solid #cfdae6;
}

.link-path {
  white-space: nowrap;
}

.logo-company {
  position: relative;
  display: flex;
  justify-content: center;
  height: 55px;
  margin-bottom: 15px;
}

.logo-company img {
  object-fit: contain;
  width: 100%;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.bg-table {
  /* padding: 15px; */
  height: 100%;
  border: 1px solid #cfdae6;
  margin-left: 20px;
}

.bg-table.space-bottom {
  margin-bottom: 20px;
}

.ant-tabs-bar {
  padding: 10px 10px 0;
}

.ant-table-pagination.ant-pagination {
  margin-left: 20px;
  margin-right: 20px;
}

/* .ant-btn {
  min-height: 35px;
} */

.is-no-padding {
  padding: 0px !important;
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none !important;
}

.tablist-top .ant-tabs-bar {
  margin: 0;
}

.tablistBColor .ant-tabs-bar {
  background-color: var(--white);
  padding-left: 40px;
  padding-right: 40px;
}

.ui-right-content.fit-height {
  min-height: 100% !important;
  height: calc(100vh - 56px);
}

.ui-topup-amount .ant-list-item {
  padding: 0 0 10px 0;
}

.topup-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.topup-box .alignment-button {
  display: flex;
  justify-content: flex-end;
}

.ant-collapse-header {
  display: flex;
  align-items: center;
  padding: 20px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: flex;
  right: 16px;
  left: unset !important;
}

.ant-collapse-borderless {
  background-color: transparent;
}

.hiddenBar {
  display: flex;
  height: 100%;
  width: 30px;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: white;
}

.ant-pagination-total-text {
  position: absolute;
  left: 20px;
}

.ant-table-pagination {
  width: 100%;
  float: none;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 0;
}

.ant-pagination-options {
  position: absolute;
  right: 8px;
}

.ant-table-pagination.ant-pagination {
  margin: 10px 0;
}

.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked:first-child,
.ant-radio-button-wrapper-checked {
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  outline: 0;
  box-shadow: unset;
}

.ant-radio-button-wrapper-checked:hover,
.ant-radio-button-wrapper:hover {
  color: rgba(0, 0, 0, 0.65);
}

.ant-tag {
  padding: 2px 8px;
}

.right-menu-container {
  left: 14%;
  top: 9px;
  min-width: 180px;
  border-radius: 0;
}

.right-menu-avatar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  right: 0;
  position: relative;
}

.ui-sidebar .home-menu.ant-menu-inline .home-menu-item .home-submenu-item {
  margin: 0;
  height: 28px !important;
  line-height: 28px !important;
}

.ui-sidebar .home-menu .home-submenu-item a {
  color: rgba(0, 0, 0, 0.65);
}

.ui-sidebar .ant-menu-item:active,
.ui-sidebar .ant-menu-submenu-title:active {
  background: none !important;
}

.ui-sidebar .home-menu .home-submenu-item.ant-menu-item > a:hover,
.ui-sidebar
  .home-menu
  .home-submenu-item.ant-menu-item.ant-menu-item-selected
  > a {
  color: #40a9ff;
}

.ui-sidebar .ant-menu-item-selected > a {
  color: #40a9ff;
}

.wraptext {
  overflow: hidden;
  white-space: pre-line;
  width: 100%;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.box-container {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 4px;
  padding: 30px;
  min-height: 100px;
  background: var(--white);
  margin: auto;
  max-width: 600px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.box-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/* .box-button .spacing-button {
  margin-right: 10px;
} */

.modal-table .ant-modal-content .ant-modal-body {
  overflow: scroll;
  padding: 0;
}

.filter-driver-modal {
  background-color: #fff;
}

.filter-driver-modal .form-container {
  border-bottom: 1px solid #f3f1f1;
}

.filter-driver-modal .altered-filter-driver-form {
  padding: 10px;
  display: flex;
  width: 100%;
}

.filter-driver-modal .error-message {
  padding: 0 20px;
  width: 100%;
}

.filter-driver-modal .add-filter-button {
  margin: 10px 20px;
}

.filter-driver-modal .altered-filter-driver-form .other-field {
  margin: 0 10px;
  width: 150px;
}

.filter-driver-modal .altered-filter-driver-form .third-column {
  margin: 0 10px;
}

.filter-driver-modal .filter-divider {
  margin-top: 0;
  margin-bottom: 0;
}

.filter-driver-modal .filter-buttons {
  float: right;
  padding: 20px;
}

.filter-driver-modal .filter-buttons .filter-left-button {
  margin-right: 15px;
}

.filter-driver-modal .filter-driver-form .filter-driver-dropdown {
  width: 160px;
}

.filter-driver-modal .altered-filter-driver-form .filter-driver-dropdown {
  width: 160px;
  margin: 0 10px;
}

.tag-font {
  font-size: 12px;
}

.box-icon {
  position: relative;
  display: flex;
  height: 50px;
  width: 50px;
  float: left;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgb(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
}

.card-custome .ant-card-body {
  padding: 0;
}

.card-custome .ant-input,
.card-custome .ant-btn-lg {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.ant-transfer-list {
  width: 46%;
  min-width: 39%;
}

.ant-transfer {
  min-width: 80%;
  max-width: 100%;
}

.spinning-box {
  background: #ffffff80;
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}

.spinning {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  min-width: 60px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 50px 5px;
  margin: auto;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.spinning.is-small {
  width: 50px;
  height: 50px;
}

.container-selection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-left-previous {
  position: absolute;
  left: 0;
  margin: auto;
}

.button-right-next {
  position: absolute;
  right: 0;
  margin: auto;
}

.icon-X2 {
  font-size: 2em;
}

.icon-center {
  text-align: center;
}

.list-slider {
  position: relative;
  align-items: center;
  justify-content: center;
  color: #dbdbdb;
}

.list-slider .is-active {
  color: rgb(0, 0, 0, 0.65);
}

.main-list-slider {
  position: relative;
  display: flex;
  margin: auto;
  width: fit-content;
  align-items: center;
}

.spacing-up-bottom {
  margin-bottom: 10px;
  margin-top: 10px;
}

.full-height {
  height: calc(100vh - 60px);
}

.half-box {
  box-shadow: 2px -6px 6px #333;
  z-index: 2;
}

.container-form {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 15px 0;
}

.container-form.input-80 {
  width: calc(100% - 40px) !important;
  margin: 0;
}

.steps-small {
  position: relative;
  display: block;
  width: 40px;
}

.steps-small .steps-height {
  height: 68px;
}

.steps-small.left {
  float: left;
}

/* -----------------------MEDIA-------------------------- */

@media (max-width: 900px) {
  .box-container {
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 4px;
    padding: 30px;
    min-height: 150px;
    background: var(--white);
    margin: 10px 10px 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
  }

  /* .box-button {
    display: grid;
  } */

  /* .box-button .spacing-button {
    margin-bottom: 10px;
  } */

  .menuVer {
    display: none;
  }

  .barsMenu {
    display: none;
  }

  .OrLine {
    float: left;
    width: 33%;
  }

  /* .leftMenu {
    display: none;
  } */

  .center {
    margin: auto;
    width: 100%;
    padding: 30px;
    max-height: fit-content;
  }

  .bg-table {
    margin-top: 20px;
    margin-left: 0;
  }

  .bg-table.spacing-bottom {
    margin-bottom: 0;
  }

  .tag-font {
    font-size: 8px;
  }

  /* .logo a {
    margin-left: -20px;
  } */

  .navbar-container .ant-menu-item,
  .navbar-container .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  /* .logo a {
    padding: 10px 20px;
  } */
}

@media (min-width: 900px) {
  .ui-right-content.form {
    /* margin-left: 230px; */
    padding: 20px;
    overflow: auto;
    flex-grow: 1;
  }

  .spacing-form-right {
    padding-right: 10px;
  }

  .spacing-form-left {
    padding-left: 10px;
  }
}

@media (max-width: 1024px) {
  .ui-right-content {
    padding: 30px;
    overflow: auto;
    flex-grow: 1;
    /* margin-bottom: 100px; */
  }

  .ant-table-content {
    overflow: auto !important;
  }

  .center {
    width: auto;
  }

  .heading1 {
    font-size: 1.5em;
  }

  .ant-table-pagination {
    width: 100% !important;
  }

  .ant-pagination-total-text {
    position: relative !important;
    /* width: 100px !important; */
    left: 0px;
  }
}

@media (min-width: 1100px) {
  .ui-right-content.no-margin-top {
    margin-top: 0;
    padding: 30px 230px !important;
  }

  .navigation-table {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .half-box {
    box-shadow: unset;
  }

  .logo-company img {
    width: 60%;
  }

  .mobile-search-toggle.right-menu-button-container {
    display: block;
    width: auto;
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 16px;
    padding-top: 5px;
  }

  .button-sidebar {
    display: none;
  }

  .button-forget {
    color: rgba(0, 0, 0, 0.65);
    float: left;
    width: 100%;
  }

  .hiddenBar {
    display: none !important;
  }

  .barsMenu {
    position: absolute;
    display: inline-flex;
  }

  .logo-company {
    height: auto;
    width: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo a {
    left: 0;
  }

  .ui-sidebar {
    padding-top: 68px;
    min-height: 100vh;
    background-color: #f0f2f5;
    border-right: 1px solid #d9d9d9;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: none;
  }

  .ui-main-content {
    display: flex;
    flex-direction: column;
    margin: 56px 0 0;
    width: 100%;
    min-height: calc(100vh - 56px);
  }

  .ui-right-content {
    margin-top: 56px;
    padding: 30px !important;
    overflow: auto;
    flex-grow: 1;
  }

  .ui-right-content.form {
    margin-top: 0px;
    padding: 20px !important;
    overflow: auto;
    flex-grow: 1;
  }

  .ui-right-content.no-margin-top {
    margin-top: 0;
    padding: 0 30px 30px 30px !important;
  }

  .heading1 {
    margin-top: 0;
    font-size: 1.5em;
  }

  .heading2 {
    font-size: 1.5em;
  }

  .heading4 {
    font-size: 0.8em;
    min-height: 32px;
  }

  .subtitle {
    font-size: 0.6em;
  }

  .ui-form-iq {
    padding: 20px;
  }

  .ui-footer {
    padding-left: 0px;
  }

  .spacing-form-right {
    padding-right: 0px;
  }

  .footer-button {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    height: auto;
  }

  .align-center.button-fullWidth {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .align-center.button-fullWidth .button-success,
  .align-center.button-fullWidth .ant-btn,
  .align-center.button-fullWidth .button-bg-transparent {
    width: 100%;
  }

  .align-center {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .footer-box {
    padding: 20px 20px;
  }

  .ui-footer {
    display: none;
    position: relative;
  }

  .checkout-button {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    bottom: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }

  .checkout-button > span {
    font-weight: 600;
    font-size: 1em;
    text-align: center;
    width: 100%;
  }

  .statistic-title {
    margin-bottom: 20px;
  }

  .statistic-title .button-bg-transparent {
    padding: 0;
  }

  .button-upload {
    position: relative;
    width: 100%;
    min-width: 98.66px;
    display: inline-block;
  }

  .button-upload .ant-upload.ant-upload-select {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button-upload .ant-upload-list {
    max-width: 178px;
    margin-top: 10px;
    min-width: 100%;
  }

  .ui-right-content.ui-topup-r {
    padding: 0px !important;
  }

  .topup-box {
    display: block;
  }

  .topup-box .alignment-button {
    display: flex;
    justify-content: flex-start;
  }

  .is-fullwidth-r {
    width: 100%;
  }

  .ui-right-content.fit-height {
    height: 100%;
  }

  .ant-layout-has-sider
    > .ui-sidebar.ant-layout-sider-collapsed
    + .ui-main-content {
    padding-left: 0px !important;
  }

  .navigation-table {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .ui-main-content {
    padding-left: 230px;
  }

  .ant-layout-has-sider
    > .ui-sidebar.ant-layout-sider-collapsed
    + .ui-main-content {
    padding-left: 64px;
  }

  .ant-layout-has-sider
    > .ui-sidebar.ant-layout-sider-collapsed
    + .ui-main-content
    .ui-right-content.ui-footer {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    position: fixed;
    bottom: 0;
    width: calc(100% - 64px);
    min-width: calc(100% - 64px);
    max-width: 100%;
    min-height: unset;
  }

  .navbar-container .left-menu-item {
    padding: 0 50px;
  }

  .navbar-container .left-menu-item.search-bar {
    padding: 0 15px;
    width: 50%;
  }

  .heading4 {
    font-size: 0.8em;
    min-height: 32px;
  }

  .subtitle {
    font-size: 0.6em;
  }

  .logo {
    width: 230px;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* margin-left: -6px; */
  }

  .logo a {
    left: 20px;
  }

  .barsMenu {
    margin-top: 0;
  }

  .spacing-form-right {
    padding-right: 10px;
  }

  .spacing-form-left {
    padding-left: 10px;
  }

  .ui-right-content.no-margin-top {
    margin-top: 0;
    /* padding: 0 30px 30px 30px !important; */
  }

  .button-upload {
    position: relative;
    width: 100%;
    min-width: 342px;
    display: inline-block;
  }

  .button-upload .ant-upload.ant-upload-select {
    float: right;
    width: 30%;
  }

  .button-upload .ant-upload-list {
    max-width: 70%;
    margin-top: 0px;
  }

  .navigation-table {
    text-align: right;
  }
}

.custom-branding-logo > .ant-upload {
  width: 274px;
  height: 137px;
}

.custom-branding-logo > .ant-upload-list-picture-card .ant-upload-list-item {
  width: 274px !important;
  height: 137px !important;
}
